import React from "react"
import moment from "moment"

import pdf from "assets/img/pdf-file.svg"

import { Button } from "components/anti/buttons/buttons"
import { useScrollAnim } from "src/components/hooks/hooks"

export const CorporateGovernancePiagam = ({ data, lang }) => {
  const rootUrl = process.env.GATSBY_ROOT_URL

  const [trigger, anim] = useScrollAnim()
  const dataLayerDownload = title => {
    window?.dataLayer?.push({
      event: "generalEvent",
      event_category: "Download Report",
      event_action: "View Report",
      event_category: { title },
    })
  }

  const dataLayerView = title => {
    window?.dataLayer?.push({
      event: "generalEvent",
      event_category: "View Report",
      event_action: "View Report",
      event_category: { title },
    })
  }

  return (
    <section className="py-5 sc-corporateGovernance-piagam" ref={trigger}>
      <div className="container">
        <h2 className={`mb-4 text-capitalize ${anim(1)}`}>{data.name}</h2>
        <div className="row row-4">
          {data.pdfFiles.nodes.map((piagam, i) => {
            const file = piagam?.pdfFiles?.file?.mediaItemUrl
            const fileId = piagam?.pdfFiles?.file?.databaseId
            return (
              <div className={`col-lg-6 ${anim(2 + i)}`} key={i}>
                <div className="piagam d-flex pb-2 mb-4">
                  <img
                    src={pdf}
                    className="img-fluid h-100 w-100px mr-3"
                    alt="pdf"
                  />
                  <div className="d-flex flex-column justify-content-center w-100">
                    <h4 className="">{piagam?.title}</h4>
                    <div>
                      <p className="d-block d-md-none text-muted">
                        {moment(piagam?.date).format("DD MMM YYYY, HH:mm ")}
                      </p>
                      <div className="d-flex justify-content-between flex-wrap">
                        <p className=" d-md-block d-none text-muted">
                          {moment(piagam?.date).format("DD MMM YYYY, HH:mm ")}
                        </p>
                        <div className="btn-group">
                          <a
                            className="btn btn-link btn-control"
                            href={file}
                            target="_blank"
                            onClick={() => dataLayerView(piagam?.title)}
                          >
                            {lang === "en" ? "View" : "Lihat"}
                          </a>
                          <a
                            className="btn btn-link btn-control ml-4"
                            href={`${rootUrl}/download/index.php?id=${fileId}`}
                            target="_blank"
                            onClick={() => dataLayerDownload(piagam?.title)}
                          >
                            {lang === "en" ? "Download" : "Unduh"}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
