import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { CorporateGovernancePiagam } from "components/pages/investor/corporate-governance/piagam"
import { CorporateGovernanceRUPS } from "components/pages/investor/corporate-governance/rups"

const CorporateGovernance = ({ pageContext, location, path }) => {
  const data = useStaticQuery(GET_CORPORATEGOVERNANCE_DETAILS)
  const lang = pageContext.langKey
  const pageData = pageContext.data

  const cover =
    lang === "en"
      ? pageData.fileCategories.cover
      : pageData.translation.fileCategories.cover ||
        pageData.fileCategories.cover
  const associationData = data.wordPress.associationPDF
  const piagamData = data.wordPress.piagamPDF
  const rupsData = data.wordPress.rupsPDF

  const { seo, backgroundColor } =
    lang === "en"
      ? pageContext.data
      : pageContext.data.translation || pageContext.data

  const SEODefaultImg = cover.image.sourceUrl
  const bgColor = backgroundColor?.backgroundColor?.color

  return (
    <Layout path={path} theme={bgColor || "light"} lang={lang}>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={SEODefaultImg}
      />
      <GlobalCover
        data={cover}
        theme={bgColor || "light"}
        breadcrumb={location.pathname}
      />
      <CorporateGovernancePiagam data={associationData} lang={lang} />
      <CorporateGovernancePiagam data={piagamData} lang={lang} />
      <CorporateGovernanceRUPS data={rupsData} lang={lang} />
    </Layout>
  )
}

export default CorporateGovernance

const GET_CORPORATEGOVERNANCE_DETAILS = graphql`
  query corporateGovernance {
    wordPress {
      associationPDF: fileCategory(
        id: "anggaran-dasar-perseroan"
        idType: SLUG
      ) {
        slug
        name
        pdfFiles(first: 10000) {
          nodes {
            title
            date
            pdfFiles {
              year
              file {
                link
                uri
                databaseId
                mediaItemUrl
              }
            }
          }
        }
      }
      piagamPDF: fileCategory(id: "piagam", idType: SLUG) {
        slug
        name
        pdfFiles(first: 10000) {
          nodes {
            title
            date
            pdfFiles {
              year
              file {
                link
                uri
                databaseId
                mediaItemUrl
              }
            }
          }
        }
      }
      rupsPDF: fileCategory(id: "rups", idType: SLUG) {
        slug
        name
        pdfFiles(first: 10000) {
          nodes {
            title
            date
            pdfFiles {
              year
              file {
                link
                uri
                databaseId
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`
